.flex {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Aligne le début des colonnes */
    overflow: hidden; /* Évite le débordement */
  }
  
  .column {
    margin: 20px; /* Espacement entre les colonnes */
    text-align: center; /* Centre le texte dans chaque colonne */
  }
  
  span {
    transition: transform 0.3s ease; /* Transition douce pour les transformations */
    white-space: nowrap; /* Évite que les prénoms ne soient coupés */
    display: inline-block; /* Permet d'ajouter des marges autour des prénoms */
    margin: 10px; /* Espace autour de chaque prénom */
  }
  
  span:hover {
    transform: scale(1.1); /* Agrandir légèrement le prénom au survol */
  }